import React from 'react';

import {
  AccountCard,
  AccountCardDetailsItem,
  AccountCardStatus,
} from '@travauxlib/shared/src/features/Chantiers/components/AccountCard';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

type Props = {
  chantier: Chantier;
  title?: string;
  ibanComponent?: React.ReactElement;
  forcedStatus?: AccountCardStatus;
  showAmounts: boolean;
};

export const InternalAccountCard: React.FC<Props> = ({
  chantier,
  title,
  ibanComponent,
  forcedStatus,
  showAmounts,
}) => {
  const getStatus = (): AccountCardStatus => {
    if (forcedStatus) {
      return forcedStatus;
    }

    if (chantier.proCompany?.archiType === 'interne') {
      return chantier.montantArchiInterneEncaisseTTC < chantier.montantTotalTTC
        ? 'uncomplete'
        : 'complete';
    }
    return chantier.montantCommissionPreleveeTTC < chantier.montantCommissionTTC ||
      chantier.montantTPEncaisseTTC < chantier.montantTravauxPlannerTTC
      ? 'uncomplete'
      : 'complete';
  };

  const getDetailsItems = (): AccountCardDetailsItem[] => {
    const commissionItem: AccountCardDetailsItem = {
      supLabel: 'Commission TTC',
      amount: chantier.montantCommissionPreleveeTTC,
      subAmount: Math.max(
        0,
        roundToTwoDecimals(chantier.montantCommissionTTC - chantier.montantCommissionPreleveeTTC),
      ),
      subSuffix: 'à capter',
    };

    const serviceHemeaDetails =
      chantier.montantServiceHemeaTTC > 0
        ? [
            {
              supLabel: `Service hemea ${chantier.tauxServiceHemea.toLocaleString()} %`,
              amount: chantier.montantServiceHemeaEncaisseTTC,
              subAmount: roundToTwoDecimals(
                chantier.montantServiceHemeaTTC - chantier.montantServiceHemeaEncaisseTTC,
              ),
              subSuffix: 'à encaisser',
            },
          ]
        : [];

    if (chantier.montantTravauxPlannerTTC > 0) {
      return [
        {
          supLabel: `Prestation TP ${chantier.tauxTravauxPlanner.toLocaleString()} %`,
          amount: chantier.montantTPEncaisseTTC,
          subAmount: roundToTwoDecimals(
            chantier.montantTravauxPlannerTTC - chantier.montantTPEncaisseTTC,
          ),
          subSuffix: 'à encaisser',
        },
        ...serviceHemeaDetails,
        commissionItem,
      ];
    }

    if (chantier.proCompany?.archiType === 'interne') {
      return [
        {
          supLabel: 'Prestation Archi Interne',
          amount: chantier.montantArchiInterneEncaisseTTC,
          subAmount: roundToTwoDecimals(
            chantier.montantTotalTTC - chantier.montantArchiInterneEncaisseTTC,
          ),
          subSuffix: 'à encaisser',
        },
        ...serviceHemeaDetails,
      ];
    }

    return [commissionItem, ...serviceHemeaDetails];
  };

  return (
    <AccountCard
      title={title || 'Compte hemea'}
      ibanComponent={ibanComponent}
      details={getDetailsItems()}
      status={getStatus()}
      showAmounts={showAmounts}
    />
  );
};
