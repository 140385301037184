import React from 'react';

import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { Chantier, ChantierStatusOption } from '@travauxlib/shared/src/features/Chantiers/types';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

type Props = {
  chantier: Chantier;
  dateDebutElement: React.ReactElement;
  dureeSuffixElement?: React.ReactElement;
  dateFinSuffixElement?: React.ReactElement;
};

export const Calendar: React.FC<Props> = ({
  chantier,
  dateDebutElement,
  dureeSuffixElement,
  dateFinSuffixElement,
}) => (
  <>
    <div
      className="flex h-[1rem] overflow-hidden bg-gray-300 text-sm !absolute !border !rounded !mx-md !shadow-xs"
      style={{
        height: '5.6rem',
        zIndex: 0,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    />
    <div className="flex flex-wrap -mx-md p-md">
      <div className="w-full px-md basis-0 relative flex-grow hidden tablet:block">
        {chantier.dateDebutReelle ? (
          <>
            Démarré le <strong>{formatFrenchDate(chantier.dateDebutReelle)}</strong>
          </>
        ) : (
          dateDebutElement
        )}
      </div>
      <div className="w-full px-md basis-0 relative flex-grow text-center">
        <h4 className="mb-xxs">
          <Tag
            size="md"
            variant={ChantierStatusOption[chantier.status].style}
            label={ChantierStatusOption[chantier.status].label}
          />
        </h4>
        <div className="flex justify-center align-items">
          {chantier.status === 'termine' ? (
            <strong>
              Durée totale : {chantier.dureeReelleDays} jour
              {chantier.dureeReelleDays > 1 && <>s</>}
            </strong>
          ) : (
            <>
              {chantier.dureeEstimeeDays} jour
              {chantier.dureeEstimeeDays > 1 && <>s</>}
              {dureeSuffixElement}
            </>
          )}
        </div>
      </div>
      <div className="px-md basis-0 relative flex-grow text-right !hidden tablet:!flex flex-col items-end">
        {chantier.dateFinReelle ? (
          <>
            Terminé le <strong>{formatFrenchDate(chantier.dateFinReelle)}</strong>
          </>
        ) : (
          <>
            <div className="mb-xs">
              Estimé le <strong>{formatFrenchDate(chantier.dateFinEstimee)}</strong>
            </div>
            {dateFinSuffixElement}
          </>
        )}
      </div>
    </div>
    <div className="flex flex-wrap -mx-md tablet:!hidden">
      <div className="w-full px-md basis-0 relative flex-grow pr-0">
        {chantier.dateDebutReelle ? (
          <>
            Démarré le <strong>{formatFrenchDate(chantier.dateDebutReelle)}</strong>
          </>
        ) : (
          <>{dateDebutElement}</>
        )}
      </div>
      <div className="w-full px-md basis-0 relative flex-grow m-0 p-0" />
      <div className="w-full px-md basis-0 relative flex-grow pl-0 text-right">
        {chantier.dateFinReelle ? (
          <>
            Terminé le <strong>{formatFrenchDate(chantier.dateFinReelle)}</strong>
          </>
        ) : (
          <>
            <div className="mb-xs">
              Estimé le <strong>{formatFrenchDate(chantier.dateFinEstimee)}</strong>
            </div>
            {dateFinSuffixElement}
          </>
        )}
      </div>
    </div>
  </>
);
