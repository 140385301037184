import React from 'react';

import Archive from '@travauxlib/shared/src/components/DesignSystem/assets/Archive.svg?react';
import Document from '@travauxlib/shared/src/components/DesignSystem/assets/Document.svg?react';
import Picture from '@travauxlib/shared/src/components/DesignSystem/assets/Picture.svg?react';
import TvaOutline from '@travauxlib/shared/src/components/DesignSystem/assets/TvaOutline.svg?react';

export const getFileIconByUrl = (url?: string): React.ReactElement => {
  const ext = url?.substr(url.lastIndexOf('.') + 1)?.toLowerCase();

  if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif' || ext === 'bmp') {
    return <Picture className="w-md h-md" />;
  }
  if (ext === 'xls' || ext === 'xlsx') {
    return <TvaOutline className="w-md h-md" />;
  }
  if (ext === 'zip' || ext === 'rar') {
    return <Archive className="w-md h-md" />;
  }

  return <Document className="w-md h-md" />;
};
