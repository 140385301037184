import React, { useState } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';

import { RadioButtonsList } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import {
  AppelDePaiement,
  AppelDeProvision,
  Chantier,
  ChantierTransaction,
  TransactionType,
} from '@travauxlib/shared/src/features/Chantiers/types';
import { Signature } from '@travauxlib/shared/src/types';

import { ChangeTransactionVisibility } from './ChangeTransactionVisibility';
import { IgnoreTransaction } from './IgnoreTransaction';
import { HistoryItem } from './types';

const ToutFilterLabel = 'Tout';
const ProvisionsFilterLabel = 'Provisions';
const PaiementsFilterLabel = 'Paiements';
const SignatureFilterLabel = 'Signatures';

const FilterLabels = [
  ToutFilterLabel,
  ProvisionsFilterLabel,
  PaiementsFilterLabel,
  SignatureFilterLabel,
];

const signatureToHistoryItem = (signature: Signature, label: React.ReactElement): HistoryItem => ({
  key: signature.uuid,
  date: signature.dateSignature,
  author: signature.customerName,
  amount:
    signature.prixTotalTTC + signature.montantTravauxPlannerTTC + signature.montantServiceHemeaTTC,
  label: label,
  className: classNames('text-primary', {
    '!line-through': signature.deletedAt,
  }),
  includeInFilter: SignatureFilterLabel,
});

const appelDeProvisionToHistoryItem = (
  appelDeProvision: AppelDeProvision,
  label: React.ReactElement,
): HistoryItem => ({
  key: appelDeProvision.uuid,
  date: appelDeProvision.dateEffet,
  author: appelDeProvision.createdBy,
  amount: appelDeProvision.montant,
  label: label,
  className: classNames('!italic', {
    '!line-through': appelDeProvision.deletedAt,
  }),
  includeInFilter: ProvisionsFilterLabel,
});

const appelDePaiementToHistoryItem = (
  appelDePaiement: AppelDePaiement,
  label: React.ReactElement,
): HistoryItem => ({
  key: appelDePaiement.uuid,
  date: appelDePaiement.createdAt,
  author: appelDePaiement.createdBy,
  amount: appelDePaiement.montant,
  label: label,
  className: classNames('!italic', {
    '!line-through': appelDePaiement.deletedAt,
  }),
  includeInFilter: PaiementsFilterLabel,
});

const TechnicalDisclaimer: React.FC<{ transaction: ChantierTransaction }> = ({ transaction }) =>
  transaction.isIgnored ? (
    <span className="text-sm mr-md">
      <Tag label="Operation technique" variant="warning" size="sm" />
      {transaction.isUserVisible === undefined
        ? ''
        : transaction.isUserVisible
          ? 'Visible'
          : 'Cachée'}
    </span>
  ) : (
    <></>
  );

const provisionToHistoryItem = (transaction: ChantierTransaction): HistoryItem => ({
  key: transaction.id.toString(),
  date: transaction.date,
  author: transaction.internalAuthor || '',
  amount: transaction.amount,
  label: (
    <>
      <TechnicalDisclaimer transaction={transaction} />
      Provision
    </>
  ),
  className: '!text-success !font-bold',
  includeInFilter: ProvisionsFilterLabel,
  canIgnore: true,
  isVisible: transaction.isUserVisible,
  isIgnored: transaction.isIgnored,
});

const TransactionTypeLabels: { [K in TransactionType]: string } = {
  liberation_de_fonds: 'Libération de fonds',
  encaissement_tp: 'Encaissement Travaux Planner',
  encaissement_service_hemea: 'Encaissement Service hemea',
  encaissement_archi_interne: 'Encaissement Archi Interne',
};

const paiementToHistoryItem = (transaction: ChantierTransaction): HistoryItem => ({
  key: transaction.id.toString(),
  date: transaction.date,
  author: transaction.internalAuthor || '',
  amount: transaction.amount,
  label: (
    <>
      <TechnicalDisclaimer transaction={transaction} />
      {transaction.transactionType
        ? TransactionTypeLabels[transaction.transactionType]
        : `Paiement vers ${transaction.creditedAccountName}`}
    </>
  ),
  className: '!text-info !font-bold',
  includeInFilter: PaiementsFilterLabel,
  canIgnore: true,
  isIgnored: transaction.isIgnored,
  isVisible: transaction.isUserVisible,
});

type Props = {
  chantier: Chantier;
  getAppelDeProvisionLabel: (a: AppelDeProvision) => React.ReactElement;
  getAppelDePaiementLabel: (a: AppelDePaiement) => React.ReactElement;
  getSignatureItemLabel: (s: Signature) => React.ReactElement;
  showAuthor: boolean;
  ignoreTransaction?: (payload: {
    chantier: Chantier;
    transactionId: number;
    ignored: boolean;
  }) => void;
  changeTransactionVisibility?: (payload: {
    chantier: Chantier;
    transactionId: number;
    visibility: boolean;
  }) => void;
};

export const CompteSequestreHistory: React.FC<Props> = ({
  chantier,
  getAppelDeProvisionLabel,
  getAppelDePaiementLabel,
  getSignatureItemLabel,
  showAuthor,
  ignoreTransaction,
  changeTransactionVisibility,
}) => {
  const unfilteredHistoryItems: HistoryItem[] = chantier.signatures
    .map(s => signatureToHistoryItem(s, getSignatureItemLabel(s)))
    .concat(
      chantier.transactions.map(transaction => {
        if (transaction.amount >= 0) {
          return provisionToHistoryItem(transaction);
        }
        return paiementToHistoryItem(transaction);
      }),
    )
    .concat(
      chantier.appelsDeProvision.map(adp =>
        appelDeProvisionToHistoryItem(adp, getAppelDeProvisionLabel(adp)),
      ),
    )
    .concat(
      chantier.appelsDePaiement.map(adp =>
        appelDePaiementToHistoryItem(adp, getAppelDePaiementLabel(adp)),
      ),
    );

  const [filter, setFilter] = useState(ToutFilterLabel);

  const onFilter = (newFilter: string): void => {
    setFilter(newFilter);

    // Trigger this only when user actually changes the filter, not in useEffect below (it would trigger at first render)
    // scrollHeight isn't up to date at this point for some reason, setTimeout necessary
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 10);
  };

  const historyItems: HistoryItem[] = unfilteredHistoryItems.filter(item =>
    [ToutFilterLabel, item.includeInFilter].includes(filter),
  );

  return (
    <>
      <div className="flex-wrap flex justify-between mb-md">
        <div className="px-md w-full tablet:max-w-5/12 hidden tablet:block">
          <form>
            <RadioButtonsList
              options={FilterLabels.map(filter => ({
                label: filter,
                value: filter,
              }))}
              value={filter}
              onChange={v => v && onFilter(v)}
              inline
            />
          </form>
        </div>
      </div>
      <Table<HistoryItem>
        items={historyItems}
        defaultSort={{
          columnKey: 'date' as const,
          order: 'desc',
        }}
        columnConfigs={[
          {
            name: 'Date',
            columnKey: 'date' as const,
            renderValue: (date: string, item: HistoryItem) => (
              <span className={item.className}>{dayjs(date).format('DD/MM/YYYY HH:mm')}</span>
            ),
          },
          {
            name: 'Auteur',
            columnKey: 'author' as const,
            renderValue: (author: string, item: HistoryItem) => (
              <span className={item.className}>{item.author}</span>
            ),
          },
          {
            name: 'Événement',
            renderValue: (_: any, item: HistoryItem) => (
              <span className={item.className}>{item.label}</span>
            ),
          },
          {
            name: 'Montant',
            columnKey: 'amount' as const,
            renderValue: (_: any, item: HistoryItem) => (
              <div className={item.className}>
                <EURCurrency
                  className="mr-xs"
                  prefix={item.label === 'Provision' && item.amount > 0 ? '+' : ''} // Hack but one day we'll refacto this component
                  amount={item.amount}
                  forceFullPattern
                />
              </div>
            ),
          },
          {
            headless: true,
            renderValue: (_: any, item: HistoryItem) => (
              <div className={item.className}>
                <IgnoreTransaction
                  item={item}
                  ignoreTransaction={
                    ignoreTransaction && item.canIgnore
                      ? () =>
                          ignoreTransaction({
                            chantier,
                            transactionId: Number(item.key),
                            ignored: !item.isIgnored,
                          })
                      : undefined
                  }
                />
                <ChangeTransactionVisibility
                  item={item}
                  changeTransactionVisibility={
                    changeTransactionVisibility && item.isIgnored && item.isVisible !== undefined
                      ? () =>
                          changeTransactionVisibility({
                            chantier,
                            transactionId: Number(item.key),
                            visibility: !item.isVisible,
                          })
                      : undefined
                  }
                />
              </div>
            ),
          },
        ].filter(column => showAuthor || column.columnKey !== 'author')}
      />
    </>
  );
};
