import React from 'react';

import classNames from 'classnames';
import { useMatch, useNavigate } from 'react-router-dom';

import { OrExclusive } from '@travauxlib/shared/src/types/utils';

import { usePermissions } from 'hooks/usePermissions';

const StyledButtonAndLink = 'text-gray-600 hover:text-dark active:text-dark';

export type ProSideBarLinkWithTextProps = {
  label: string;
  icon?: React.ReactElement;
  bottomLink?: boolean;
  external?: boolean;
  pathsToMatch?: string[];
  isFullyOpen: boolean;
  bodyClassName?: string;
} & OrExclusive<{ path: string }, { onClick: () => void }>;

export const ProSideBarLinkWithText: React.FC<ProSideBarLinkWithTextProps> = ({
  path,
  icon,
  label,
  bottomLink = false,
  external,
  onClick,
  isFullyOpen,
  bodyClassName,
}) => {
  const isActive = useMatch(`${path}/*`);
  const { protectedNavigate } = usePermissions();
  const navigate = useNavigate();

  const content = (
    <>
      {icon && (
        <div className="!p-xxs !rounded !mr-sm !flex !items-center transition-bg duration-125 ease-in">
          {icon}
        </div>
      )}
      <span
        className={classNames({
          'text-sm !font-bold': bottomLink,
          invisible: !isFullyOpen,
        })}
      >
        {label}
      </span>
    </>
  );

  const className = classNames(
    '!flex !items-center !w-full !font-bold !whitespace-nowrap',
    bottomLink ? '!mb-md !text-dark' : 'mb-xmd',
    '!pl-md',
    bodyClassName,
    {
      active: isActive,
    },
  );

  if (onClick) {
    return (
      <button
        className={classNames(className, 'p-0 bg-transparent', StyledButtonAndLink)}
        onClick={onClick}
        type="button"
      >
        {content}
      </button>
    );
  }
  if (external) {
    return (
      <a
        className={classNames(className, StyledButtonAndLink)}
        href={path}
        target="_blank"
        rel="noopener noreferrer"
      >
        {content}
      </a>
    );
  }

  return (
    <button
      type="button"
      data-testid={path}
      className={classNames(className, 'p-0 bg-transparent', StyledButtonAndLink)}
      onClick={() =>
        path === '/centrale-achat'
          ? protectedNavigate(path!, 'read', 'CentraleAchat')
          : navigate(path!)
      }
    >
      {content}
    </button>
  );
};
